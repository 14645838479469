<template>
  <div class="mx-2 pb-32">
    <div class="text-left text-xl max-w-14 leading-tight max-w-xs mb-2">
      <h2 class="font-semibold text-onboarding_gray">Foodies seleccionados</h2>
    </div>
    <foodie-card :foodies="foodies"/>
  </div>
</template>

<script>
import FoodieCard from '../components/foodie-card'

export default {
  name: 'foodiesList',
  components: { FoodieCard },
  props: {
    foodies: { required: false, type: Array }
  }
}
</script>

<style scoped>

</style>
