<template>
  <div class="h-full">
    <Header :header="header"></Header>
    <div class="pt-14">
      <div v-if="loading" class="my-4 mx-2">
        <a-loader class="h-8 w-8 block mx-auto mt-4" :color="'text-gray'"/>
      </div>
      <div v-else class="my-4 mx-2">
        <card class="mb-4" :foodies="foodies" :collaboration="collaboration" :price="productPriceSum"/>
        <div class="">
          <a-stepper :slots="7">
            <template #step-1>
              <foodies-list :foodies="foodies" />
            </template>

            <template #step-2>
              <Companions v-model:companions="collaboration.companions_limit" />
            </template>

            <template #step-3>
              <Products :products="products" v-model:selectedProducts="selectedProductsIds" />
            </template>

            <template #step-4>
              <Calendar v-model:start_at="collaboration.start_at" v-model:end_at="collaboration.end_at" />
            </template>

            <template #step-5>
              <available-days v-model:allowed_week_days="collaboration.allowed_week_days" />
            </template>

            <template #step-6>
              <Requirements :products="selectedProducts" v-model:requirements="collaboration.requirements" />
            </template>

            <template #step-7>
              <Resume
                  :foodies="foodies"
                  :collaboration="collaboration"
                  :products="selectedProducts"
                  :price="productPriceSum"
                  @create-collab="createCollab"
                  :loading="loadingCreate"
              />
            </template>

            <template #footer="{ index, goPrev, goNext }" >
              <div class="flex fixed bottom-12 justify-between items-center p-4 -mx-2 px-2 w-full custom-width ">
                <button
                    v-if="index !== 1"
                    @click="goPrev"
                    class="bg-unconnected_gray text-primary left-4 bottom-20 focus:outline-none flex justify-center text-black p-2 rounded-full uppercase transition-all ease-in-out duration-500 disabled:bg-onboarding_light_gray disabled:text-black"
                >
                  <button class="rounded-full h-10 w-10">
                    <i class="icon-arrowLeft"></i>
                  </button>
                </button>
                <div v-else class="block w-32"></div>

                <button
                    v-if="index !== 7"
                    :disabled="disableNext(index)"
                    @click="goNext"
                    class="bg-primary text-white bottom-20 right-4 focus:outline-none flex justify-center p-2 rounded-full uppercase disabled:bg-onboarding_light_gray transition-all ease-in-out duration-300"
                >
                  <button class="rounded-full h-10 w-10">
                    <i class="icon-arrowRight"></i>
                  </button>
                </button>
              </div>
            </template>
          </a-stepper>
        </div>
      </div>
    </div>
    <a-alert hide-cancel :show="modals.create" :confirm-class="'text-center text-primary block mx-auto'" :confirm-text="'Ver mis colaboraciones'" @confirm="$router.push({ path: '/collaboration' })">
      <div class="text-center font-bold text-lg block mx-auto w-4/5 mt-sm">
        <h2>¡Enhorabuena!</h2>
        <h2>Tu colaboración premium ha sido creada</h2>
      </div>
    </a-alert>
  </div>
</template>

<script>

import { ref, onMounted, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useRepository, useSession } from '../../../../../hooks'
import Card from './components/card'
import Header from '@/components/authenticated/Header'
import FoodiesList from './steps/foodiesList'
import Companions from './steps/companions'
import Products from './steps/products'
import Calendar from './steps/calendar'
import AvailableDays from './steps/availableDays'
import Requirements from './steps/requirements'
import Resume from './steps/resume'

export default {
  name: 'collaborationPremium',
  components: {
    Requirements,
    Products,
    Header,
    Card,
    Companions,
    FoodiesList,
    Calendar,
    AvailableDays,
    Resume
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const header = ref({ type: 'explore.show' })
    const foodieRepository = useRepository(({ foodies }) => foodies)
    const collabsRepository = useRepository(({ collaborations }) => collaborations)
    const { user } = useSession()
    const foodies = ref([])
    const products = ref([])
    const loading = ref(false)
    const loadingCreate = ref(false)
    const modals = ref({ create: false })

    const selectedProductsIds = ref([])
    const collaboration = ref({
      type: 'private',
      foodies_meeting: false,
      contact_name: user?.restaurant?.contact_name,
      contact_phone: user?.restaurant?.phone,
      discount_type: 'prc',
      discount_value: 100,
      companions_limit: 1,
      start_at: '',
      end_at: '',
      is_premium: true,
      allowed_week_days: [],
      min_ig_followers: null,
      foodies: [],
      requirements: ''
    })

    const productPriceSum = computed(() => {
      let price = 0

      products.value.forEach((product) => {
        if (selectedProductsIds.value.includes(product.id)) {
          price = parseFloat(price) + parseFloat(product.price_e2)
        }
      })

      return price
    })

    const selectedProducts = computed(() => {
      return products.value.filter((product) => (selectedProductsIds.value.includes(product.id)))
    })

    onMounted(() => {
      loading.value = true
      foodieRepository.show(route.params.id)
        .then(({ data }) => {
          foodies.value = [data]
          collaboration.value.foodies.push({ id: data.id })
          if (!data.is_premium) {
            router.push({ path: '/explore' })
          }
        })
        .then(() => {
          collabsRepository.requirements()
            .then(({ data }) => {
              collaboration.value.requirements = data.default_requirements ? data.default_requirements : ''
            })
          collabsRepository.defaultDays()
            .then(({ data }) => {
              if (data.default_allowed_week_days.length > 0) {
                collaboration.value.allowed_week_days = data.default_allowed_week_days.map((day) => {
                  return parseInt(day)
                })
              } else {
                collaboration.value.allowed_week_days = []
              }
            })
        })
        .finally(() => {
          foodieRepository.products(foodies?.value[0]?.id)
            .index()
            .then(({ data }) => {
              products.value = data.data
            })
            .finally(() => (loading.value = false))
        })
    })

    const disableNext = (index) => {
      switch (index) {
        case 1: {
          return false
        }
        case 2: {
          return collaboration.value.companions_limit === 0
        }
        case 3: {
          return selectedProductsIds.value.length === 0
        }
        case 4: {
          return !collaboration.value.start_at || !collaboration.value.end_at
        }
        case 5: {
          return collaboration.value.allowed_week_days.length === 0
        }
        case 6: {
          return !collaboration.value.requirements
        }
      }
    }

    const createCollab = () => {
      // console.log('creating collab')
      // console.log(collaboration.value)
      loadingCreate.value = true
      collaboration.value.foodies[0] = { ...collaboration.value.foodies[0], product_ids: selectedProductsIds.value }
      collabsRepository.store(collaboration.value)
        .then((res) => {
          modals.value.create = true
        })
        .finally(() => (loadingCreate.value = false))
    }

    return {
      header,
      foodies,
      products,
      loading,
      loadingCreate,
      selectedProductsIds,
      collaboration,
      productPriceSum,
      selectedProducts,
      modals,
      createCollab,
      disableNext
    }
  }
}
</script>

<style scoped>

@media (min-width: 1024px) {
  .custom-width {
    width: 1024px;
  }
  .custom-right {
    right: calc((100vw / 2) - (512px - 1rem)) !important;
  }
  .custom-left {
    left: calc((100vw / 2) - (512px - 1rem)) !important;
  }
}

</style>
