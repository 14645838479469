<template>
  <div v-for="foodie in foodies" :key="foodie" class="mb-2">
    <div class="flex border rounded-lg p-2">
      <div>
        <a-initials :name="foodie?.name" :src="foodie?.profile_picture"></a-initials>
      </div>
      <div class="flex flex-col flex-grow text-left mx-2">
        <p class="font-medium">{{ '@' + foodie.ig_username }}</p>
        <p class="text-2xs text-onboarding_light_gray">{{ followersFormatter(foodie.ig_followers_count) + ' followers'}}</p>
      </div>
      <button v-if="showButton" type="button" class="mx-2 text-lg"><span><i class="icon-close"></i></span></button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'foodie-card',
  props: {
    foodies: { required: false, type: Array },
    showButton: { required: false, type: Boolean, default: true }
  },
  setup () {
    const followersFormatter = (followers) => {
      if (followers >= 1000000) {
        return parseFloat(followers / 1000000).toFixed(1) + 'M'
      } else if (followers >= 1100) {
        return parseFloat(followers / 1000).toFixed(1) + 'k'
      }
      return followers
    }

    return {
      followersFormatter
    }
  }
}
</script>

<style scoped>

</style>
