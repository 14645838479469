<template>
  <div class="mx-2 pb-32">
    <div class="text-left text-xl max-w-14 leading-tight max-w-xs mb-2">
      <h2 class="font-semibold text-onboarding_gray">Requerimientos</h2>
    </div>
    <div class="mt-4">
      <textarea
          v-model="requirementsValue"
          rows="6"
          maxlength="400"
          @change="handleRequirements"
          class="border rounded-xl w-full p-2 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50 disabled:bg-gray">
      </textarea>
      <div class="text-right text-gray text-sm -mt-1">
        <p>{{ requirementsValue?.length + ' / 400' }}</p>
      </div>
    </div>
    <div class="grid grid-col-1 gap-1 mt-4">
      <div v-for="product in products" :key="product">
        <div class="flex border text-onboarding_gray p-2 rounded-md text-left">
          <p class="flex-grow">{{ product.description }}</p>
          <h3 class="font-bold w-14 text-right text-lg text-onboarding_gray">{{ $filters.currencyFormatter(product.price_e2 / 100) + '€' }}</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'

export default {
  name: 'Requirements',
  props: {
    products: { required: false, type: Array },
    requirements: { required: false, type: String }
  },
  emits: ['update:requirements'],
  setup (props, { emit }) {
    const requirementsValue = ref('')

    const handleRequirements = () => {
      emit('update:requirements', requirementsValue.value)
    }

    onMounted(() => {
      requirementsValue.value = props.requirements
    })

    return {
      requirementsValue,
      handleRequirements
    }
  }
}
</script>'

<style scoped>

</style>
