<template>
  <div class="mx-2 pb-32">
    <div class="text-left text-xl leading-tight mb-2 w-full">
      <h2 class="font-semibold text-onboarding_gray text-center">Elije un rango de fecha</h2>
    </div>
    <div>
      <CalendarComponent class="flex-col" v-model="date" :inline="true" selectionMode="range" date-format="dd/mm - " :minDate="minDate" />
      <p class="mt-2 max-w-xs block mx-auto">En el siguiente paso puedes especificar los días de la semana</p>
    </div>
  </div>
</template>

<script>
import CalendarComponent from 'primevue/calendar'
import { ref, watch, onMounted } from 'vue'
import Moment from 'moment'

export default {
  name: 'Calendar',
  components: {
    CalendarComponent
  },
  props: {
    start_at: { required: false },
    end_at: { required: false }
  },
  emits: ['update:start_at', 'update:end_at'],
  setup (props, { emit }) {
    const minDate = ref(new Date())
    const date = ref('')

    watch(date, (value) => {
      if (value[0] && value[1] && value[0] !== null && value[1] !== null) {
        emit('update:start_at', Moment(value[0]).utc().format('YYYY-MM-DD'))
        emit('update:end_at', Moment(value[1]).utc().format('YYYY-MM-DD'))
      }
    })

    onMounted(() => {
      emit('update:start_at', '')
      emit('update:end_at', '')
    })

    return {
      minDate,
      date
    }
  }
}
</script>

<style scoped>

</style>
