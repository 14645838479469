<template>
  <div class="mx-2 pb-32">
    <div class="text-left text-xl max-w-14 leading-tight max-w-xs">
      <h2 class="font-semibold text-onboarding_gray">Resumen de la colaboración</h2>
    </div>
    <div>
      <p class="text-left text-sm text-onboarding_light_gray">Por favor, confirma los detalles de la colaboración. Solofoodies retendrá el importe hasta que el foodie complete el trabajo. Si el foodie no acepta realizar la colaboración, no se te cobrará.</p>
    </div>

    <div class="border rounded-lg text-left mt-2 px-4 py-2">
      <h3 class="text-onboarding_gray text-lg font-semibold">Requerimientos</h3>
      <p class="text-sm">{{ collaboration?.requirements ? collaboration.requirements : 'Sin requerimientos' }}</p>
    </div>

    <div class="border rounded-lg text-left mt-2 px-4 py-2">
      <div class="grid grid-col-1 gap-2">
        <h3 class="text-onboarding_gray text-lg font-semibold">Ofertas foodie premium</h3>
        <div v-for="product in products" :key="product">
          <div class="flex border-onboarding_ligth_gray rounded-md text-left">
            <p class="text-sm">{{ product?.description }}</p>
            <div class="flex-grow"></div>
            <h3 class="font-semibold text-right text-lg text-onboarding_gray">{{ getProductPrice(product)}}</h3> <!--TODO tiene que salir IVA ejm: 80euros + 16.5 euros IVA-->
          </div>
        </div>
      </div>
    </div>

    <resume-card
        :collaboration="collaboration"
        :foodies="foodies"
        :price="price"
        :loading="loading"
        @create-collab="$emit('create-collab')"/>

    <div>
      <div class="text-left text-xl max-w-14 leading-tight max-w-xs mb-2">
        <h2 class="font-semibold text-onboarding_gray">Foodies seleccionados</h2>
      </div>
      <foodie-card :foodies="foodies" :showButton="false" />
    </div>

  </div>
</template>

<script>
import ResumeCard from '../components/resume-card'
import FoodieCard from '../components/foodie-card'

export default {
  name: 'Resume',
  components: { FoodieCard, ResumeCard },
  props: {
    foodies: { required: false, type: Object },
    collaboration: { required: false, type: Object },
    products: { required: false, type: Object },
    price: { required: false, type: Number },
    loading: { required: false, type: Boolean }
  },
  emits: ['create-collab'],
  methods: {
    getProductPrice (product) {
      const productPrice = this.$filters.currencyFormatter(product.price_e2 / 100)
      const iva = ((product.price_e2 / 100) * 0.21)
      // this.product?.price_e2 / 100 + '€ + ' + (product?.price_e2 / 100) * 0.21 + '€ IVA'
      return `${productPrice}€ + ${this.$filters.currencyFormatter(iva)}€ IVA`
    }
  }
}
</script>

<style scoped>

</style>
