<template>
  <div class="mx-2 pb-32">
    <div class="text-left text-xl max-w-14 leading-tight max-w-xs mb-2 flex">
      <h2 class="font-semibold text-onboarding_gray">Ofertas Foodie Premium</h2>
      <div class="flex-none flex items-center justify-center bg-yellow_ribbon rounded-full h-6 w-6 ml-1">
        <i class="pi pi-star" style="font-size: 12px"></i>
      </div>
    </div>

    <div v-if="products.length === 0 || !products">
<!--      <a-loader class="block mx-auto h-6 w-6"></a-loader>-->
      <a-skeleton class="bg-onboarding_light_gray" height="5rem"></a-skeleton>
      <a-skeleton class="bg-onboarding_light_gray" height="5rem"></a-skeleton>
      <a-skeleton class="bg-onboarding_light_gray" height="5rem"></a-skeleton>
    </div>
    <div v-else class="wrapper flex-1">
      <div v-for="product in products" :key="product.id">
        <input type="checkbox" :id="product.id" :value="product.id" v-model="picked">
        <label :for="product.id" class="flex flex-col text-onboarding_gray option option-1 border border-midgray rounded-lg p-normal mt-4" >
          <span class="flex justify-between flex-auto">
            <span class="title block text-left mr-2">{{ product.description }}</span>
            <span class="block text-xl font-bold min-w-32">{{ $filters.currencyFormatter(product.price_e2 / 100) + '€' }}</span>
          </span>
          <span class="self-end ml-2 mt-2">
            <button class="bg-onboarding_gray text-white rounded-full px-3 py-small" @click="handleSelection(product)">{{ selectedProducts.includes(product.id) ? 'Seleccionado' : 'Seleccionar' }}</button>
          </span>
        </label>
      </div>
    </div>
    <div class="flex-1 flex items-end pb-4 mt-4">
      <h1>*En todo momento puedes volver atrás y editar tus selecciones</h1>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue'

export default {
  name: 'Products',
  props: {
    products: { required: true, type: Array },
    selectedProducts: { required: false }
  },
  emits: ['update:selectedProducts'],
  setup (props, { emit }) {
    const picked = ref([])

    const handleSelection = (product) => {
      // emit('update:selectedProducts', picked.value)
      if (picked.value.includes(product.id)) {
        const getIndex = picked.value.indexOf(product.id)

        picked.value.splice(getIndex, 1)
      } else {
        picked.value.push(product.id)
      }
      emit('update:selectedProducts', picked.value)

      // picked.value = e.value
    }

    onMounted(() => {
      picked.value = props.selectedProducts
    })

    watch(picked, (value) => {
      emit('update:selectedProducts', picked.value)
    })

    return {
      picked,
      handleSelection
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper .option{
  //cursor: pointer;
  transition: all 0.3s ease;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"]{
  &:checked + label {
    //background-color: #8E8E8E;
    @apply bg-onboarding_gray text-white;

    button {
      @apply text-onboarding_gray bg-white;
    }
  }
}

//#option-1:checked:checked ~ .option-1,
//#option-2:checked:checked ~ .option-2{
//  /*border-color: #cf1a1a;*/
//  background-color: #8E8E8E;
//  color: #fff;
//}
//
//#option-1:checked:checked ~ .option-1 button,
//#option-2:checked:checked ~ .option-2 button{
//  /*border-color: #cf1a1a;*/
//  background-color: #397EF3;
//  box-shadow: 0 2px 5px 1px #605858;
//  border: none;
//}
</style>
