<template>
  <div class="mx-2 pb-32">
    <div class="leading-tight mb-2 w-full">
      <h2 class="font-semibold text-onboarding_gray text-xl text-center">Elegir días disponibles</h2>
<!--      <p class="block mx-auto">Toca en los días para seleccionar o deseleccionar</p>-->
    </div>
    <div class="grid grid-cols-1 gap-1 mt-4">
      <div v-for="(day, index) in daysList" :key="day">
        <input type="checkbox" :value="parseInt(index + 1)" v-model="days" :id="index">
        <label :for="index" class="py-2 px-4 day-container cursor-pointer rounded-full text-onboarding_light_gray border border-onboarding_light_gray">{{ day }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue'

export default {
  name: 'availableDays',
  props: {
    allowed_week_days: { required: true }
  },
  emits: ['update:allowed_week_days'],
  setup (props, { emit }) {
    const daysList = ref(['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'])
    const days = ref([])

    watch(days, (value) => {
      emit('update:allowed_week_days', value.sort())
    })

    onMounted(() => {
      days.value = props.allowed_week_days
    })

    return {
      daysList,
      days
    }
  }
}
</script>

<style lang="scss" scoped>

input[type="checkbox"] {
  display: none;
}

.day-container {
  width: 10rem;
  display: block;
  margin: 0 auto;
}
input[type="checkbox"]{
  &:checked + label {
   //background-color: #8E8E8E;
     @apply bg-onboarding_gray text-SFRed
  }
}

</style>
