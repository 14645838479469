<template>
  <div class="mx-2 pb-32">
    <div class="text-left text-xl max-w-14 leading-tight max-w-xs mb-2">
      <h2 class="font-semibold text-onboarding_gray">Número de acompañantes permitidos por foodies</h2>
    </div>
    <div class="flex bg-onboarding_gray rounded-md">
      <div class="self-center mx-4">
        <button
            class="bg-white text-onboarding_gray focus:outline-none flex justify-center text-black p-2 rounded-full uppercase transition-all ease-in-out duration-500 disabled:bg-onboarding_light_gray disabled:text-black"
            @click="handleCompanions('subs')"
        >
          <button class="rounded-full h-10 w-10 text-xl">
            <span>-</span>
          </button>
        </button>
      </div>
      <div class="flex-grow flex flex-col items-center my-6">
        <div class="text-white text-4xl"><h2>{{ companions }}</h2></div>
        <div class="text-SFRed"><h3>acompañantes</h3></div>
      </div>
      <div class="self-center mx-4">
        <button
            class="bg-white text-onboarding_gray focus:outline-none flex align-items-center justify-center text-black p-2 rounded-full uppercase transition-all ease-in-out duration-500 disabled:bg-onboarding_light_gray disabled:text-black"
            @click="handleCompanions('sum')"
        >
          <button class="rounded-full h-10 w-10 text-xl">
            <span>+</span>
          </button>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Companions',
  props: {
    companions: { required: false, type: Number }
  },
  emits: ['update:companions'],
  setup (props, { emit }) {
    const handleCompanions = (type) => {
      if (type === 'sum') {
        emit('update:companions', props.companions + 1)
      } else {
        if (props.companions > 0) {
          emit('update:companions', props.companions - 1)
        }
      }
    }

    return {
      handleCompanions
    }
  }
}
</script>

<style scoped>

</style>
