<template>
  <div class="flex-1 text-left border rounded-lg my-2 p-4 text-white bg-onboarding_gray">
    <h3 class="text-lg font-semibold mb-2">Colaboración por invitación</h3>

    <div class="">
      <i class="icon-user pr-2 inline-block align-middle text-SFRed"></i>
      <p class="inline-block align-middle">
        {{ foodies.length === 1 ? `${foodies.length} foodie invitado` : `${foodies.length} foodies invitados` }}
      </p>
    </div>

    <div class="mt-normal">
      <i class="icon-calendar-01 pr-2 inline-block align-middle text-SFRed"></i>
      <p class="inline-block capitalize align-middle">
        {{ `Del ${$filters.moment(collaboration.start_at, 'DD/MM/YY')} al ${$filters.moment(collaboration.end_at, 'DD/MM/YY')}`}}
      </p>
    </div>

    <div class="mt-normal">
      <i class="icon-tc pr-2 inline-block align-middle text-SFRed"></i>
      <p class="inline-block align-middle">
        {{ `100% Descuento + ${ $filters.currencyFormatter(price / 100)}€ + ${$filters.currencyFormatter((price / 100) * 0.21)}€ IVA`}}
      </p>
    </div>

    <div class="mt-normal">
      <i class="icon-add_foodie pr-2 inline-block align-middle text-SFRed"></i>
      <p class="inline-block align-middle">
        {{ collaboration.companions_limit }} acompañantes máx.
      </p>
    </div>

    <div class="mt-normal flex items-start">
      <i class="icon-clock pr-2 inline-block align-middle text-SFRed"></i>
      <div>
        <p class="inline-block align-middle" :class="collaboration.allowed_week_days.length > 5 ? 'text-sm' : ''">
          {{ collaboration.allowed_week_days.map(day => daysList[day - 1]).join(', ') }}
        </p>
      </div>
    </div>

    <div class="mt-normal">
      <button
          class="button block mx-auto bg-yellow_ribbon w-full text-onboarding_gray sm:w-full md:w-2/4 lg:w-1/4"
          :disabled="loading"
          @click="$emit('create-collab')"
      >
        <a-loader v-if="loading" class="h-6 w-6 block mx-auto" :color="'text-onboarding_gray'" />
        <span v-else>
          <i class="pi pi-star"></i>
          <span>Crear colaboración ({{ $filters.currencyFormatter((price  / 100) * 0.21 + (price / 100)) }}€ IVA Incl.)</span> <!-- TODO aqui debe salir entre el parentesis el precio total incluyendo el iva-->
        </span>
      </button>
    </div>

<!--    <div class="pb-32 mt-normal">-->
<!--      <div v-for="foodie in foodieValues" :key="foodie" class="py-2">-->
<!--        <div class="flex border rounded-full w-full p-1 items-center">-->
<!--          <a-initials :src="foodie.profile_picture" :name="foodie.name" class="flex-none"/>-->
<!--          <h2 class="pl-2 flex-auto">{{ foodie.ig_username ? '@' + foodie.ig_username : foodie.name }}</h2>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'resume-card',
  props: {
    collaboration: { required: true, type: Object },
    foodies: { required: false, type: Array },
    price: { required: false, type: Number },
    loading: { required: false, type: Boolean }
  },
  emits: ['create-collab'],
  setup (props) {
    const daysList = ref(['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'])

    return {
      daysList
    }
  }
}
</script>

<style scoped>

</style>
