<template>
  <div class="flex flex-col border rounded-lg">
    <div class="flex p-2">
      <div class="flex-none">
        <div class="donut completed_donut" style="color: #397ef3 !important">
          <div class="donut__slice donut__slice__second"></div>
          <div class="donut__label">
            <i class="icon-user"></i>
            <p>1</p>
          </div>
        </div>
      </div>

      <div class="flex-grow grid grid-col-1 text-left mx-2">
        <div class="text-sm">{{ collaboration.start_at && collaboration.end_at ? `Del ${$filters.moment(collaboration.start_at, 'DD/MM/YYYY')} al ${$filters.moment(collaboration.end_at, 'DD/MM/YYYY')}` : 'Fecha' }}</div>
<!--        <div v-html="getDate"></div>-->
        <div class="font-regular grid grid-cols-7 items-center text-center" style="max-width: 250px;">
          <div v-for="day in days" :key="day" class="justify-center items-center">
            <div
                class="flex items-center justify-center"
                style="width: 20px; height: 20px; min-width: 20px;"
                :class="{'bg-onboarding_light_gray text-white rounded-full': collaboration.allowed_week_days.includes(day.id)}">
              <p class="sm:text-sm">
                {{ day.label }}
              </p>
            </div>
          </div>
        </div>
        <p class="font-regular">
          <i class="icon-user"></i>
           +{{ collaboration.companions_limit }} acompañantes
        </p>
        <!--p class="font-light">Fecha de creación:</p>
        <p class="font-light gray_color">{{ getCreationDate }}</p-->
      </div>

      <div class="flex-none flex flex-col ml-4 items-end">
        <div class="flex items-center justify-center bg-yellow_ribbon rounded-full h-6 w-6">
          <i class="pi pi-star" style="font-size: 12px"></i>
        </div>
        <b class="text-xl text-black">{{ `${collaboration.discount_value} ${collaboration.discount_type === 'prc' ? '%' : '€'}` }}</b>
        <b class="text-xl text-black">{{ '+' + $filters.currencyFormatter(price / 100) + '€'}}</b>
      </div>

<!--      <div class="flex-1 flex flex-col">-->
<!--        <div>rango de fechas</div>-->
<!--        <div>dias</div>-->
<!--        <div>Acompañantes</div>-->
<!--      </div>-->
<!--      <div class="flex-none flex flex-col">-->
<!--        <div>icon</div>-->
<!--        <div>100%</div>-->
<!--        <div>Cumulative</div>-->
<!--      </div>-->
    </div>
    <div class="flex border-t px-2 py-1">
      <div>Foodies: <span v-for="foodie in foodies" :key="foodie">{{ '@' + foodie.ig_username + ' ' }}</span></div>
    </div>
  </div>
</template>

<script>

import { ref } from 'vue'

export default {
  name: 'card',
  props: {
    foodies: { required: false, type: Array },
    collaboration: { required: false, type: Object },
    price: { required: false }
  },
  setup (props) {
    const value = ref()
    const days = ref([
      { label: 'L', id: 1 },
      { label: 'M', id: 2 },
      { label: 'M', id: 3 },
      { label: 'J', id: 4 },
      { label: 'V', id: 5 },
      { label: 'S', id: 6 },
      { label: 'D', id: 7 }
    ])

    return {
      value,
      days
    }
  }
}
</script>

<style scoped>

</style>
